import styled, { css } from "styled-components";
import { Modal, ModalTitle } from "react-bootstrap";

const BoldFont = "Rubik-Medium";
const MediumFont = "Rubik-Regular";
const StyledModal = styled(Modal)`
  .modal-dialog {
    transform: ${({ transform }) =>
    transform
      ? "none !important"
      : "translate(0, 50vh) translate(0, -50%) !important"};
    margin: ${({ change }) => (change ? "150px auto" : "0 auto")};
    @media (max-height: 460px) {
      transform: none !important;
      margin: 30px auto;
    }
    width: ${({ change }) => (change ? "500px" : "490px")};
    height: ${({ change }) => (change ? "573px" : "473px")};
    @media (max-width: 500px) {
      width: 95%;
      height: auto;
    }
  }

  .modal-body{
    padding : ${({hide, padding}) =>padding?padding: hide ? 0 : '25px 0'}
  }

  .modal-container {
    display: ${({ hide }) => (hide ? "none" : "flex")};
    justify-content: space-between;
    align-items: center;
    padding: 0 15px 0 25px;
    color: white;
    background: #005c87;
    border-radius: 6px 6px 0px 0px;
    height: 50px;

    > div:nth-child(2) {
      cursor: pointer;
    }
  }

  .left-modal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    > div:nth-child(2) {
      font-family: "Rubik-Medium";
    }
    .text {
      font-family: Rubik-Medium;
      font-size: 18px;
      line-height: 24px;
      text-transform: capitalize;
      color: #ffffff;
    }
  }

  .workout-image {
    padding-right: 10px;
    > img {
      width: 40px;
      height: 40px;
    }
  }

  .upArrow {
    width: 14px;
  }

  .downArrow {
    width: 14px;
  }

  .upArrowContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 5px;
    cursor: pointer;
  }

  .downArrowContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 5px;
    cursor: pointer;
  }
`;
const StyledTitle = styled(ModalTitle)`
  font-size: 28px;
  letter-spacing: 0px;
  color: #67676a;
  font-family: ${BoldFont};
  text-align: center;
`;
const StyledSubTitle = styled.p`
  font-size: 14px;
  letter-spacing: 0px;
  color: rgb(51, 51, 51);
  text-align: center;
  font-family: ${MediumFont};
`;
const SelectYourWorkOut = styled.div`
  width: 96%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0px 10px;
  > div:first-child {
    padding-bottom: 10px;
    font-family: "Rubik-Medium";
    color: #ffffff;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    color: #0D4270;
  }
  > div:last-child {
    font-family: ${BoldFont};
    padding-bottom: 10px;
    cursor: pointer;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #7dc5ff;
  }
  @media (max-width: 500px) {
    padding: 0px;
    width: 100%;
  }
`;
const WorkoutCategoryContainer = styled.div`
  width: 100%;
  padding: 0px 0px 0px 25px;
  background: #ffffff;
  border-radius: 6px;
`;
const CategoryContainer = styled.div`
  flex-basis: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
  @media (max-width: 550px) {
    justify-content: center;
  }
  ::-webkit-scrollbar {
    width: 7px;
    height: 5px;
    @media (max-width: 500px) {
      width: 5px;
    }
  }

  ::-webkit-scrollbar-thumb {
    background: #c6c2c2;
    cursor: pointer;
    border-radius: 2.5px;
  }

  ::-webkit-scrollbar-track {
    background: #f2f0f0;
    border-radius: 2.5px;
  }
`;

const WorkoutCategoryList = styled.div`
  background-image: url(${({ bgUrl }) => bgUrl})};
  width: 202px;
  height: 148px;
  margin: 12.5px 25px 12.5px 0px;
  background-size: 100% 100%;
  image-rendering: pixelated;
  border-radius: 6px;
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  position: relative;
  > div{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    position: relative;
    > div{
      text-align: center;
      padding-top: 10px;
      font-family: 'Rubik-Medium';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #FFFFFF;
    }
  }
  @media (max-width: 550px){
    flex-basis: 190px;
    height: 190px;
  }
  .bg-opacity {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.329545);
    border-radius: 6px;
  }
`;
const WorkoutRoutine = styled.div`
  width: 100%;
  font-family: ${MediumFont};
  font-size: 18px;
  margin-top: 40px;
  color: #282c37;
  text-align: center;
`;
const DailyLogWorkoutContainer = styled.div`
  width: 100%;
`;
const CategoryImageContainer = styled.div`
  width: 100%;
  position: relative;
  .bg-opacity {
    position: absolute;
    height: 200px;
    width: 100%;
    top: 0;
    left: 0;
    background: rgba(30, 118, 171, 0.6);
    border-radius: 6px;
  }
`;
const CategoryImage = styled.div`
  width: 100%;
  height: 200px;
  background-image: url(${({ bgUrl }) => bgUrl})};
  background-size: 100%;
  background-position: center;
  image-rendering: pixelated;
  border-radius: 6px;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;
const CategoryName = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  > div {
    font-size: 20px;
    color: #ffffff;
    font-family: ${BoldFont};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 200px;
  }
`;
const CategoryInputFieldContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-bottom: 40px;
`;
const HeaderContainer = styled.div`
  width: 100%;
  font-family: ${BoldFont};
  font-size: 16px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #323c47;
  opacity: 0.5;
  padding: 15px;
  text-align: center;
`;
const CategoryInputField = styled.button`
  width: calc(100% / 3);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: transparent;
  border: none;
  > div:first-child {
    color: #323c47;
    font-family: ${BoldFont};
    font-size: 12px;
    line-height: 12px;
    color: ${({ active }) => (active ? "#4798D9" : "#323C47")};
  }
  >div: last-child {
    font-size: 19px;
    line-height: 23px;
    color: ${({ active }) => (active ? "#4798D9" : "#ADB1B5")};
  }
  outline: none;
  ${({ disabled }) =>
    disabled === 1 &&
    css`
      opacity: 0.5;
      cursor: not-allowed !important;
    `}
`;
const InputCategoryContainer = styled.div`
  width: 100%;
  display: flex;
  align-item: center;
  justify-content: center;
  flex-wrap: wrap;
`;
const InputDistanceContainer = styled.div`
  width: 50%;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 500px) {
    width: 70%;
  }
`;
const InputDistance = styled.div`
  width: 100%;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  >div: first-child {
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-width: 288px;
    cursor: default;
    margin-top: 20px;
    > div {
      font-size: 12.6px;
      line-height: 23px;
      color: #323c47;
      font-family: ${BoldFont};
    }
  }
`;
const ContinueButton = styled.button`
  width: 235px;
  padding: 17px 0;
  background: linear-gradient(0deg, #1e76ab, #1e76ab);
  border-radius: 25px;
  border: none;
  color: #fff;
  font-size: 15px;
  font-family: ${MediumFont};
  text-transform: uppercase;
  outline: none;
  ${({ disabled }) =>
    disabled === true &&
    css`
      opacity: 0.5;
      cursor: not-allowed !important;
    `}
  @media (max-width: 500px) {
    width: 140px;
  }
`;
const IntensityValue = styled.div`
  color: #323c47;
  font-size: 28px;
  font-family: ${BoldFont};
  margin: 10px auto 5px;
  text-align: center;
  width: 100%;
`;
const IntensityKey = styled.div`
  color: #323C47;
  font-size: 15px;
  font-family: ${BoldFont};
  min-height: 21px;
  width: 100%
  text-align: center;
  margin-top: -20px;
  text-transform: capitalize;
`;
const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${({ marginTop }) => (marginTop ? "50px" : "35px")};
`;

const TimerWrapper = styled.div`
  textarea:focus,
  input:focus {
    outline: none;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

const TimerImg = styled.div`
  display: flex;
  justify-content: center;
  > img {
    width: 144px;
    height: 144px;
  }
`;

const TimerHead = styled.div`
  font-family: "Rubik-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  color: #0D4270;
`;

const TimerPicker = styled.div`
  padding: 30px 30px;
  display: flex;
`;

const TimerButton = styled.div`
  padding: 5px 30px;
  margin-top: 10px;
  cursor: pointer;
  position: relative;
  z-index: 1000;
  > div {
    align-items: center;
    display: flex;
    justify-content: center;
    background: #fd7175;
    border-radius: 6px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 16px;
    text-align: center;
    color: #ffffff;
    height: 50px;
  }
`;

const TimerHour = styled.div`
  width: 30%;
  text-align: center;
  > div:first-child {
    height: 25%;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 17px;
    text-align: center;
    color: #3a3a3a;
  }

  .picker {
    flex-direction: column;
    background: #eaeaea;
    border-radius: 3px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    > div:first-child {
      height: 20%;
      width: 100%;
      cursor: pointer;
      position: relative;
      z-index: 100;
    }
    > div:nth-child(2) {
      height: 60%;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      font-family: "Rubik";
      font-style: normal;
      font-weight: normal;
      font-size: 64px;
      line-height: 17px;
      text-align: center;
      color: #3a3a3a;
    }
    > div:last-child {
      height: 20%;
      width: 100%;
      cursor: pointer;
      position: relative;
      z-index: 100;
    }
  }
`;

const TimerSemi = styled.div`
  width: 5%;
  > div:first-child {
    height: 25%;
  }
  > div:nth-child(2) {
    height: 75%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const TimerMinute = styled.div`
  width: 30%;
  text-align: center;
  > div:first-child {
    height: 25%;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 17px;
    text-align: center;
    color: #3a3a3a;
  }
  .picker {
    flex-direction: column;
    background: #eaeaea;
    border-radius: 3px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    > div:first-child {
      height: 20%;
      width: 100%;
      cursor: pointer;
      position: relative;
      z-index: 100;
    }
    > div:nth-child(2) {
      height: 60%;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      font-family: "Rubik";
      font-style: normal;
      font-weight: normal;
      font-size: 64px;
      line-height: 17px;
      text-align: center;
      color: #3a3a3a;
    }
    > div:last-child {
      height: 20%;
      width: 100%;
      cursor: pointer;
      position: relative;
      z-index: 100;
    }
  }
`;

const TimerSecond = styled.div`
  width: 30%;
  text-align: center;
  > div:first-child {
    height: 25%;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 17px;
    text-align: center;
    color: #3a3a3a;
  }
  .picker {
    flex-direction: column;
    background: #eaeaea;
    border-radius: 3px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    > div:first-child {
      height: 20%;
      width: 100%;
      cursor: pointer;
      position: relative;
      z-index: 100;
    }
    > div:nth-child(2) {
      height: 60%;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      font-family: "Rubik";
      font-style: normal;
      font-weight: normal;
      font-size: 64px;
      line-height: 17px;
      text-align: center;
      color: #3a3a3a;
    }
    > div:last-child {
      height: 20%;
      width: 100%;
      cursor: pointer;
      position: relative;
      z-index: 100;
    }
  }
`;

const MilesWrapper = styled.div`
  textarea:focus,
  input:focus {
    outline: none;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

const MilesImg = styled.div`
  text-align: center;
  > img {
    width: 122px;
  }
`;

const MilesContent = styled.div`
  margin: 10px 0;
  font-family: "Rubik-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  color: #0D4270;
`;

const MilesHead = styled.div`
  margin: 15px 0;
  font-family: "Rubik";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 17px;
  text-align: center;
  color: #3a3a3a;
`;

const MilesPicker = styled.div`
  margin: 15px 0;
  display: flex;
  .dotMiles {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 24px;
    position: relative;
    top: 65px;
    left: 18px;
  }
  > div:first-child {
    width: 20%;
  }
  > div:nth-child(2) {
    width: 25%;
    display: flex;
    flex-direction: column;
    height: 100px;
    background: #eaeaea;
    border-radius: 3px;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 64px;
    line-height: 17px;
    text-align: center;
    color: #3a3a3a;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  > div:nth-child(3) {
    width: 10%;
  }

  > div:nth-child(4) {
    display: flex;
    flex-direction: column;
    width: 25%;
    height: 100px;
    background: #eaeaea;
    border-radius: 3px;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 64px;
    line-height: 17px;
    text-align: center;
    color: #3a3a3a;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  > div:nth-child(5) {
    width: 20%;
  }
`;

const CongoWrapper = styled.div`
  background: linear-gradient(180deg, #f6b479 0%, #ffa24e 100%);
  border-radius: 6px;
`;

const CongoHead = styled.div`
  height: 10%;
  padding: 20px 0 10px 0;
  text-align: center;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  color: #ffffff;
`;

const CongoContent = styled.div`
  font-family: "Rubik";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  height: 10%;
  text-align: center;
  margin-bottom: 20px;
`;

const CongoImg = styled.div`
  height: 50%;
  margin: 20px 0;
  text-align: center;
`;

const CongoDesc = styled.div`
  margin: 10px 0;
  height: 10%;
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
`;

const CongoButton = styled.div`
  padding: 20px 0;
  height: 20%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  > div {
    width: 50%;
    font-family: "Rubik";
    font-style: normal;
    cursor: pointer;
    font-weight: 500;
    font-size: 18px;
    line-height: 16px;
    color: #0D4270;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    border-radius: 6px;
    text-align: center;
  }
`;

const CalendarWrapper = styled.div`
  padding: 0 25px;
`;

const CalendarImg = styled.div`
  text-align: center;
  > img {
    width: 179.46px;
    height: 107.08px;
  }
`;

const CalendarHead = styled.div`
  margin: 20px 0;
  font-family: "Rubik-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
  text-align: center;
  color: #0D4270;
`;

const CalendarPicker = styled.div``;

const IntensityWrapper = styled.div``;

const IntensityIcon = styled.div`
  text-align: center;
  > img {
    width: 134.94px;
    height: 117.52px;
  }
`;

const IntensityHead = styled.div`
  font-family: "Rubik-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  color: #0D4270;
  margin: 20px 0;
`;

const IntensityPicker = styled.div`
  width: 100%;
  padding: 0 30px;
`;

const IntensityPart = styled.div`
  width: 100%;
  padding: 10px 20px;
  > div {
    cursor: pointer;
    width: 100%;
    > img {
      width: 100%;
    }
  }
`;

const IntensityCurrent = styled.div`
  display: flex;
  padding: 10px 30px;
  > div:first-child {
    width: ${({ width }) => (width ? `calc(${width}% - 25px)` : "0%")};
  }
  > div:nth-child(2) {
    position: relative;
    width: ${({ width }) => (width ? `${100 - width}%` : "0%")};
    > span {
      position: absolute;
      left: 15px;
      top: 17px;
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: #ffffff;
    }
  }
`;

const CalendarContainer = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 3px;
  @media (min-width: 1550px) {
    width: 100%;
  }
  @media (max-width: 1450px) and (min-width: 1300px) {
    width: 100%;
  }
  @media (max-width: 1250px) {
    width: 100%;
  }
  .react-calendar {
    width: 100%;
    height: 100%;
    justify-content: flex-start;

    .react-calendar__navigation {
      display: flex;
      padding: 10px 20px;
      height: 15%;
      align-items: center;
      justify-content: space-between;

      button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
        border: none;
        background-color: white;
        display: none;
      }
      button.react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
        border: none;
        background-color: white;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #fd7175;
        color: white;
        font-size: 25px;

        &:focus {
          outline: none;
        }
      }
      button.react-calendar__navigation__arrow.react-calendar__navigation__next-button {
        border: none;
        background-color: white;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #fd7175;
        color: white;
        font-size: 25px;

        &:focus {
          outline: none;
        }
      }
      button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
        border: none;
        background-color: white;
        display: none;
      }
      .react-calendar__navigation__label {
        border: none;
        background-color: white;
        background-color: rgba(253, 113, 117, 0.11);
        border-radius: 24px;
        max-width: 200px;
        min-height: 30px;

        &:focus {
          outline: none;
        }
        span.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
          font-size: 18px;
          font-family: "Rubik-Medium";
          background: -webkit-linear-gradient(
            90deg,
            #006fbc -1.11%,
            #7f43ff 97.46%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
    .react-calendar__viewContainer {
      padding: 15px;
      width: 100%;
      height: 85%;

      @media (max-width: 1250px) {
        padding: 0px;
      }

      .react-calendar__month-view {
        width: 100%;
        height: 100%;
        background-color: #fff;

        .react-calendar__month-view__weekdays {
          .react-calendar__tile.react-calendar__tile--active.react-calendar__month-view__weekdays__weekday {
            border-bottom: 4px solid #000;
          }
          .react-calendar__month-view__weekdays__weekday {
            padding-bottom: 10px;
            ${"" /* border-bottom: 4px solid #AFAFAF; */}
            text-align: center;
            abbr[title] {
              border-bottom: none;
              text-decoration: none;
              color: #afafaf;
            }
          }
        }
        .react-calendar__month-view__days {
          margin-top: 8px;
          justify-content: space-evenly;
          background-color: #fff;
          height: 100%;
          width: 100%;

          button.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day,
          button.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
            background: #fd7175;
            flex-basis: unset !important;
            max-width: unset !important;
            overflow: unset !important;
            border: none;
            border-radius: 3px;
            width: 54px;
            height: 54px;
            margin: 3px 0;
            &:focus {
              outline: none;
            }

            > abbr {
              font-size: 13px;
              line-height: 19px;
              color: #fff;
              text-align: center;
              font-family: Rubik;
              font-size: 18px;
            }

            @media (min-width: 1550px) {
              margin: 3px 0;
            }
            @media (max-width: 1450px) and (min-width: 1300px) {
              margin: 5px 0;
            }
            @media (max-width: 1250px) {
              min-height: 33px;
              width: 33px;
              margin: 14px;
            }
            @media (max-width: 1223px) {
              margin: 13px;
            }
            @media (max-width: 1195px) {
              margin: 12px;
            }
            @media (max-width: 1167px) {
              margin: 11px;
            }
            @media (max-width: 1059px) {
              margin: 10px;
            }
            @media (max-width: 1046px) {
              margin: 9px;
            }
            @media (max-width: 1003px) {
              margin: 8px;
            }
            @media (max-width: 975px) {
              margin: 7px;
            }
            @media (max-width: 950px) {
              margin: 15px;
            }
            @media (max-width: 931px) {
              margin: 14px;
            }
            @media (max-width: 903px) {
              margin: 13px;
            }
            @media (max-width: 875px) {
              margin: 12px;
            }
            @media (max-width: 847px) {
              margin: 11px;
            }
            @media (max-width: 819px) {
              margin: 10px;
            }
            @media (max-width: 791px) {
              margin: 9px;
            }
            @media (max-width: 763px) {
              margin: 8px;
            }
            @media (max-width: 735px) {
              margin: 7px;
            }
            @media (max-width: 707px) {
              margin: 6px;
            }
            @media (max-width: 688px) {
              margin: 28px;
            }
            @media (max-width: 642px) {
              margin: 27px;
            }
            @media (max-width: 628px) {
              margin: 25px;
            }
            @media (max-width: 600px) {
              margin: 24px;
            }
            @media (max-width: 586px) {
              margin: 23px;
            }
            @media (max-width: 572px) {
              margin: 22px;
            }
            @media (max-width: 559px) {
              margin: 21px;
            }
            @media (max-width: 544px) {
              margin: 20px;
            }
            @media (max-width: 530px) {
              margin: 19px;
            }
            @media (max-width: 516px) {
              margin: 18px;
            }
            @media (max-width: 502px) {
              margin: 17px;
            }
            @media (max-width: 488px) {
              margin: 16px;
            }
            @media (max-width: 474px) {
              margin: 15px;
            }
            @media (max-width: 460px) {
              margin: 14px;
            }
            @media (max-width: 446px) {
              margin: 13px;
            }
            @media (max-width: 432px) {
              margin: 12px;
            }
            @media (max-width: 418px) {
              margin: 11px;
            }
            @media (max-width: 404px) {
              margin: 10px;
            }
            @media (max-width: 390px) {
              margin: 9px;
            }
            @media (max-width: 376px) {
              margin: 8px;
            }
            @media (max-width: 362px) {
              margin: 7px;
            }
            @media (max-width: 348px) {
              margin: 6px;
            }
            @media (max-width: 334px) {
              margin: 5px;
            }
            @media (max-width: 320px) {
              margin: 4px;
            }
          }
          ${({ bgColor }) =>
    bgColor &&
            css`
              button.react-calendar__tile.react-calendar__tile--active {
                background: linear-gradient(
                  90deg,
                  #006fbc -1.11%,
                  #7f43ff 97.46%
                ) !important;
                color: white !important;
                border: none !important;

                > abbr {
                  font-size: 13px !important;
                  line-height: 19px;
                  color: #fff !important;
                  text-align: center;
                  font-family: Rubik;
                }
              }
            `}

          button.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend {
            background-color: white;
            flex-basis: unset !important;
            max-width: unset !important;
            overflow: unset !important;
            width: 54px;
            height: 54px;
            border: none;
            background: #f8f8f8;
            border-radius: 3px;
            margin: 3px 0;
            > abbr {
              font-family: "Rubik";
              font-style: normal;
              font-weight: normal;
              font-size: 18px;
              line-height: 16px;
              text-align: center;
              color: #3a3a3a;
            }
          }

          button.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
            margin: 0;
            background: none;
            border: none;

            > abbr {
              display: none;
            }

            &:focus {
              outline: none;
            }

            @media (min-width: 1550px) {
              margin: 3px 0;
            }
            @media (max-width: 1450px) and (min-width: 1300px) {
              margin: 5px 0;
            }
            @media (max-width: 1250px) {
              min-height: 33px;
              width: 33px;
              margin: 14px;
            }
            @media (max-width: 1223px) {
              margin: 13px;
            }
            @media (max-width: 1195px) {
              margin: 12px;
            }
            @media (max-width: 1167px) {
              margin: 11px;
            }
            @media (max-width: 1059px) {
              margin: 10px;
            }
            @media (max-width: 1046px) {
              margin: 9px;
            }
            @media (max-width: 1003px) {
              margin: 8px;
            }
            @media (max-width: 975px) {
              margin: 7px;
            }
            @media (max-width: 950px) {
              margin: 15px;
            }
            @media (max-width: 931px) {
              margin: 14px;
            }
            @media (max-width: 903px) {
              margin: 13px;
            }
            @media (max-width: 875px) {
              margin: 12px;
            }
            @media (max-width: 847px) {
              margin: 11px;
            }
            @media (max-width: 819px) {
              margin: 10px;
            }
            @media (max-width: 791px) {
              margin: 9px;
            }
            @media (max-width: 763px) {
              margin: 8px;
            }
            @media (max-width: 735px) {
              margin: 7px;
            }
            @media (max-width: 707px) {
              margin: 6px;
            }
            @media (max-width: 688px) {
              margin: 28px;
            }
            @media (max-width: 642px) {
              margin: 27px;
            }
            @media (max-width: 628px) {
              margin: 25px;
            }
            @media (max-width: 600px) {
              margin: 24px;
            }
            @media (max-width: 586px) {
              margin: 23px;
            }
            @media (max-width: 572px) {
              margin: 22px;
            }
            @media (max-width: 559px) {
              margin: 21px;
            }
            @media (max-width: 544px) {
              margin: 20px;
            }
            @media (max-width: 530px) {
              margin: 19px;
            }
            @media (max-width: 516px) {
              margin: 18px;
            }
            @media (max-width: 502px) {
              margin: 17px;
            }
            @media (max-width: 488px) {
              margin: 16px;
            }
            @media (max-width: 474px) {
              margin: 15px;
            }
            @media (max-width: 460px) {
              margin: 14px;
            }
            @media (max-width: 446px) {
              margin: 13px;
            }
            @media (max-width: 432px) {
              margin: 12px;
            }
            @media (max-width: 418px) {
              margin: 11px;
            }
            @media (max-width: 404px) {
              margin: 10px;
            }
            @media (max-width: 390px) {
              margin: 9px;
            }
            @media (max-width: 376px) {
              margin: 8px;
            }
            @media (max-width: 362px) {
              margin: 7px;
            }
            @media (max-width: 348px) {
              margin: 6px;
            }
            @media (max-width: 334px) {
              margin: 5px;
            }
            @media (max-width: 320px) {
              margin: 4px;
            }
          }

          button.react-calendar__tile.react-calendar__month-view__days__day {
            background-color: white;
            flex-basis: unset !important;
            max-width: unset !important;
            overflow: unset !important;
            width: 54px;
            height: 54px;
            border: none;
            background: #f8f8f8;
            border-radius: 3px;
            margin: 3px 0;
            > abbr {
              font-family: "Rubik";
              font-style: normal;
              font-weight: normal;
              font-size: 18px;
              line-height: 16px;
              text-align: center;
              color: #3a3a3a;
            }

            &:focus {
              outline: none;
            }

            @media (min-width: 1550px) {
              margin: 3px 0;
            }
            @media (max-width: 1450px) and (min-width: 1300px) {
              margin: 5px 0;
            }
            @media (max-width: 1250px) {
              min-height: 33px;
              width: 33px;
              margin: 14px;
            }
            @media (max-width: 1223px) {
              margin: 13px;
            }
            @media (max-width: 1195px) {
              margin: 12px;
            }
            @media (max-width: 1167px) {
              margin: 11px;
            }
            @media (max-width: 1059px) {
              margin: 10px;
            }
            @media (max-width: 1046px) {
              margin: 9px;
            }
            @media (max-width: 1003px) {
              margin: 8px;
            }
            @media (max-width: 975px) {
              margin: 7px;
            }
            @media (max-width: 950px) {
              margin: 15px;
            }
            @media (max-width: 931px) {
              margin: 14px;
            }
            @media (max-width: 903px) {
              margin: 13px;
            }
            @media (max-width: 875px) {
              margin: 12px;
            }
            @media (max-width: 847px) {
              margin: 11px;
            }
            @media (max-width: 819px) {
              margin: 10px;
            }
            @media (max-width: 791px) {
              margin: 9px;
            }
            @media (max-width: 763px) {
              margin: 8px;
            }
            @media (max-width: 735px) {
              margin: 7px;
            }
            @media (max-width: 707px) {
              margin: 6px;
            }
            @media (max-width: 688px) {
              margin: 28px;
            }
            @media (max-width: 642px) {
              margin: 27px;
            }
            @media (max-width: 628px) {
              margin: 25px;
            }
            @media (max-width: 600px) {
              margin: 24px;
            }
            @media (max-width: 586px) {
              margin: 23px;
            }
            @media (max-width: 572px) {
              margin: 22px;
            }
            @media (max-width: 559px) {
              margin: 21px;
            }
            @media (max-width: 544px) {
              margin: 20px;
            }
            @media (max-width: 530px) {
              margin: 19px;
            }
            @media (max-width: 516px) {
              margin: 18px;
            }
            @media (max-width: 502px) {
              margin: 17px;
            }
            @media (max-width: 488px) {
              margin: 16px;
            }
            @media (max-width: 474px) {
              margin: 15px;
            }
            @media (max-width: 460px) {
              margin: 14px;
            }
            @media (max-width: 446px) {
              margin: 13px;
            }
            @media (max-width: 432px) {
              margin: 12px;
            }
            @media (max-width: 418px) {
              margin: 11px;
            }
            @media (max-width: 404px) {
              margin: 10px;
            }
            @media (max-width: 390px) {
              margin: 9px;
            }
            @media (max-width: 376px) {
              margin: 8px;
            }
            @media (max-width: 362px) {
              margin: 7px;
            }
            @media (max-width: 348px) {
              margin: 6px;
            }
            @media (max-width: 334px) {
              margin: 5px;
            }
            @media (max-width: 320px) {
              margin: 4px;
            }
          }
        }
      }
      .react-calendar__year-view {
        width: 100%;
        height: 100%;

        .react-calendar__year-view__months {
          height: 100%;
          width: 100%;
          justify-content: space-evenly;

          button.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--hasActive.react-calendar__year-view__months__month {
            border: none;
            background-color: #fff;
            font-size: 16px;
            font-family: "Rubik-Medium";
            background: -webkit-linear-gradient(
              90deg,
              #006fbc -1.11%,
              #7f43ff 97.46%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            &:focus {
              outline: none;
            }
          }

          button.react-calendar__tile.react-calendar__year-view__months__month {
            border: none;
            background-color: #fff;
            color: #000000;
            font-size: 16px;
            font-family: "Rubik-Regular";

            &:focus {
              outline: none;
            }
          }
        }
      }
      .react-calendar__decade-view {
        width: 100%;
        height: 100%;

        .react-calendar__decade-view__years {
          width: 100%;
          height: 100%;

          button.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--hasActive.react-calendar__decade-view__years__year {
            border: none;
            background-color: #fff;
            font-size: 16px;
            font-family: "Rubik-Medium";
            background: -webkit-linear-gradient(
              90deg,
              #006fbc -1.11%,
              #7f43ff 97.46%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            &:focus {
              outline: none;
            }
          }

          button.react-calendar__tile.react-calendar__decade-view__years__year {
            border: none;
            background-color: #fff;
            color: #000000;
            font-size: 16px;
            font-family: "Rubik-Regular";

            &:focus {
              outline: none;
            }
          }
        }
      }
      .react-calendar__century-view {
        height: 100%;
        width: 100%;

        .react-calendar__century-view__decades {
          height: 100%;
          width: 100%;

          button.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--hasActive.react-calendar__century-view__decades__decade {
            border: none;
            background-color: #fff;
            font-size: 16px;
            font-family: "Rubik-Medium";
            background: -webkit-linear-gradient(
              90deg,
              #006fbc -1.11%,
              #7f43ff 97.46%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            &:focus {
              outline: none;
            }
          }

          button.react-calendar__tile.react-calendar__century-view__decades__decade {
            border: none;
            background-color: #fff;
            color: #000000;
            font-size: 16px;
            font-family: "Rubik-Regular";

            &:focus {
              outline: none;
            }
          }
        }
      }
    }
  }
`;

const MilesOne = styled.div`
  > div:first-child {
    width: 100%;
    height: 20%;
    cursor: pointer;
    position: relative;
    z-index: 1000;
  }
  > div:nth-child(2) {
    height: 60%;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 100;
  }
  > div:last-child {
    width: 100%;
    height: 20%;
    cursor: pointer;
    position: relative;
    z-index: 1000;
  }
`;

const MilesSecond = styled.div`
  > div:first-child {
    width: 100%;
    height: 20%;
    cursor: pointer;
    position: relative;
    z-index: 100;
  }
  > div:nth-child(2) {
    height: 60%;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  > div:last-child {
    width: 100%;
    height: 20%;
    cursor: pointer;
    position: relative;
    z-index: 100;
  }
`;

export {
  StyledModal,
  StyledTitle,
  StyledSubTitle,
  WorkoutCategoryContainer,
  SelectYourWorkOut,
  CategoryContainer,
  WorkoutCategoryList,
  WorkoutRoutine,
  HeaderContainer,
  DailyLogWorkoutContainer,
  CategoryImage,
  CategoryImageContainer,
  CategoryInputField,
  CategoryInputFieldContainer,
  CategoryName,
  InputCategoryContainer,
  InputDistanceContainer,
  InputDistance,
  ContinueButton,
  IntensityValue,
  IntensityKey,
  ButtonContainer,
  TimerWrapper,
  TimerImg,
  TimerHead,
  TimerPicker,
  TimerButton,
  TimerHour,
  TimerSemi,
  TimerMinute,
  TimerSecond,
  MilesWrapper,
  MilesImg,
  MilesContent,
  MilesHead,
  MilesPicker,
  MilesOne,
  MilesSecond,
  CongoWrapper,
  CongoHead,
  CongoContent,
  CongoImg,
  CongoDesc,
  CongoButton,
  CalendarWrapper,
  CalendarImg,
  CalendarHead,
  CalendarPicker,
  CalendarContainer,
  IntensityPart,
  IntensityWrapper,
  IntensityIcon,
  IntensityHead,
  IntensityPicker,
  IntensityCurrent,
};
